<template>
    <div class="report_basic" v-loading="state.loading">
        <div class="container">
            <small-title tit="生成报告配置"></small-title>
            <el-divider />
            <el-form ref="formDom" :model="state.form" :rules="state.rules" label-position="left" label-width="120px">
                <el-form-item label="显示范围Top" prop="topSize">
                    <el-input type="number" v-model.number="state.form.topSize"
                        oninput="value=value.replace(/[\D]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="图片报告页选择" prop="imagePageId">
                    <el-select v-model="state.form.imagePageId">
                        <el-option v-for="item in state.pageList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="目标报告页选择" prop="targetPageId">
                    <el-select v-model="state.form.targetPageId">
                        <el-option v-for="item in state.pageList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="对比报告页选择" prop="contrastPageIds">
                    <el-select multiple v-model="state.form.contrastPageIds">
                        <el-option v-for="item in state.pageList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button @click="$router.go(-1)">上一步，维护数据</el-button>
                    <el-button type="primary" @click="onSubmit(formDom)">下一步，生成报告</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, reactive, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import SmallTitle from '@/components/common/SmallTitle.vue';
import { reportPageDict, reportConfigGetInfo, reportConfigSave } from '@/request/dishApi';
import { ElMessage } from 'element-plus';

const $router = useRouter();
const $route = useRoute();
const formDom = ref(null);
const state = reactive({
    reportId: $route.query.id,
    loading: false,
    pageList: [],//页面列表
    form: {
        topSize: 20,
        imagePageId: '',//图片报告页Id
        targetPageId: '',//目标报告页
        contrastPageIds: [],//对比报告页
    },
    rules: {
        topSize: [{ required: true, message: '请输入显示范围Top', trigger: 'blur' }],
        imagePageId: [{ required: true, message: '请选择图片报告页', trigger: 'change' }],
        targetPageId: [{ required: true, message: '请选择目标报告页', trigger: 'change' }],
        contrastPageIds: [{ required: true, message: '请选择对比报告页', trigger: 'change' }]
    }
})

// 获取下拉选项
async function getItemList() {
    const { data } = await reportPageDict({ reportId: state.reportId })
    state.pageList = data || []
    getHistoryData()
}

// 获取历史数据-回显
async function getHistoryData() {
    state.loading = true;
    const { data } = await reportConfigGetInfo({ reportId: state.reportId })
    state.form.topSize = data?.topSize || 20;
    state.form.imagePageId = data?.imagePageId || '';
    state.form.targetPageId = data?.targetPageId || '';
    state.form.contrastPageIds = data?.contrastPageIds || [];
    state.loading = false;
}

// 提交验证
function onSubmit(formEl) {
    if (!formEl) return
    formEl.validate((valid) => {
        if (valid) {
            if (state.form.topSize > 500) {
                ElMessage.error('显示范围Top不能大于500')
                return;
            } else {
                submitRequest()
            }
        }
    })
}

// 提交
async function submitRequest() {
    state.loading = true;
    const { code } = await reportConfigSave({ ...state.form, reportId: state.reportId })
    state.loading = false;
    if (code !== '0') return;
    $router.push({ path: '/dishsearch/reportresult1', query: { id: state.reportId } });
}

onBeforeMount(() => {
    getItemList()
})
</script>

<style lang="scss" scoped>
.report_basic {
    padding: 20px 40px;

    .container {
        background-color: #fff;
        padding: 20px;
        border-radius: 14px;

        .el-select,
        .el-input {
            width: 50%;
        }
    }
}
</style>